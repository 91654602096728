<template>
  <v-container fill-height fluid ma-0 pa-0 :style="backgroundStyle">
    <v-row v-if="initialized" align="center" justify="center">
      <v-col>
        <v-sheet>
          <v-card
            class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
            max-width="100%"
            outlined
            :style="cardStyles"
          >
            <v-row align="center" justify="center">
              <v-col>
                <p :class="titleClasses">{{ title }}</p>
                <p :class="subtitleClasses">{{ subtitle }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, } from 'vuex'
import { ROUTES } from '../constants'
import { push } from '../router'
import { transformError } from '../services/utility.service'

export default {
  data: () => ({
    initialized: false,

    uid: null,
    token: null,
    activated: null,
  }),
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
    }),
    title () {
      if (this.activated === null) {
        return 'Activating Account'
      }
      else if (this.activated) {
        return 'Account Activated'
      }
      else {
        return 'Account Activation Error'
      }
    },
    cardStyles () {
      if (this.activated === true) {
        return 'background-color: RGBA(165, 214, 167, 0.3) !important;'
      }
      else if (this.activated === false) {
        return 'background-color: RGBA(239, 154, 154, 0.3) !important;'
      }
    },
    subtitle () {
      if (this.activated === null) {
        return 'Please wait...'
      }
      else if (this.activated) {
        return 'Redirecting to sign-in page'
      }
      else {
        return 'If the error persists, contact your InvisMI iCare administator.'
      }
    },
    subtitleClasses () {
      let classes = 'mt-4 body-1 text-center mb-1'
      if (this.activated === null) {
        return `secondary--text  ${classes}`
      }
      else if (this.activated) {
        return `success--text  ${classes}`
      }
      else {
        return `error--text  ${classes}`
      }
    },
    titleClasses () {
      let classes = 'font-weight-bold text-h4 text-center mb-0'
      if (this.activated === null) {
        return `secondary--text ${classes}`
      }
      else if (this.activated) {
        return `success--text ${classes}`
      }
      else {
        return `error--text ${classes}`
      }
    },
  },
  methods: {
    ...mapActions({
      activateUser: 'user/activateUser',
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
      setBackground: 'sitemap/setBackground',
    }),
  },
  async created() {
    this.setBackground()
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.initialized = true

    this.activateUser({
      uid: this.$route.params.uid,
      token: this.$route.params.token
    })
      .then(async response => {
        this.activated = true
        this.successMessage('Account activation successful.')
        await new Promise(resolve => setTimeout(resolve, 1000));
        push(ROUTES.SIGN_IN)
      })
      .catch(error => {
        if (error.status_code === 403) {
          error.message = 'Activation URL has expired.'
        }
        this.activated = false
        this.errorMessage(`Failed to activate account. ${transformError(error)}`)
      })
  }
}
</script>
